<template>
    <modal ref="modalAgregarCarpeta" titulo="Crear Carpeta" adicional="Crear" @adicional="crearCarpeta">
        <div class="row justify-content-center m-3 f-15">
            <div class="col-12 my-2">
                <ValidationObserver ref="addFolderServicio">
                    <p class="input-label-top">Nombre de la carpeta</p>
                    <ValidationProvider v-slot="{errors}" name="nombre" rules="required">
                        <el-input v-model="nombreCarpeta" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return {
            nombreCarpeta: '',
            idProyecto: '',
            idControlPiso: '',
            editing: false,
            idDocumento: null
        }
    },
    methods: {
        ...mapActions({
            Action_crear_carpeta: 'obras/servicios/documentacion/Action_crear_carpeta',
            Action_edit_carpeta: 'obras/servicios/documentacion/Action_edit_carpeta'
        }),
        toggle(idProyecto, idControlPiso, data){
            this.$refs.addFolderServicio.reset()
            this.idProyecto = idProyecto
            this.idControlPiso = idControlPiso
            if (data) {
                this.nombreCarpeta = data.nombre
                this.editing = true
                this.idDocumento = data.id
            }
            this.$refs.modalAgregarCarpeta.toggle()
        },
        async crearCarpeta(){
            let validCreate = await this.$refs.addFolderServicio.validate()
            let obj = {
                "idControlPiso": this.idControlPiso,
                "idProyecto": this.idProyecto,
                "nombre" : this.nombreCarpeta
            }

            if (validCreate) {
                if (this.editing) {
                    obj.idDocumento = this.idDocumento
                    await this.Action_edit_carpeta(obj)
                } else {
                    await this.Action_crear_carpeta(obj)
                    this.nombreCarpeta = ''
                }
                this.$refs.modalAgregarCarpeta.toggle()
            }
            
        }
    }
}
</script>