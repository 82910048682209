<template>
    <section class="container-fluid section-servicios-documentacion custom-scroll overflow-auto">
        <titulo-divisor titulo="Carpetas" class="mx-0">
            <div class="row">
                <div class="col-auto  pl-1">
                    <button class="btn btn-general f-12 px-3" @click="crearCarpeta">Crear carpeta</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <p class="text-general f-14 f-600 text-center py-3 border-bottom-g mb-2 shadow29">Carpetas</p>
                    <div class="listado-carpetas px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2">
                        <card-folder v-for="data in foldersList" :key="data.id" :class="`${idFolderSelected == data.id ? 'selected' : ''}`"
                        :folderName="data.nombre"
                        :cantFiles="data.cantidad_archivos"
                        @editFolder="editFolder(data)"
                        @deleteFolder="deleteFolder(data.id)"
                        @action="loadFolderFiles(data.id)"
                        />
                    </div>
                </div> 
            </div>
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <div class="row border-bottom-g f-600 f-14 mx-0 mb-2 shadow29">
                        <div class="col-md-3 col-lg-3 my-auto text-general">
                            <p class="py-3">Archivos</p>
                        </div>
                        <div class="col-md-3 col-lg-1 my-auto text-center text-general">
                            <p class="py-3">Ver</p>
                        </div>
                        <div class="col-md-3 col-lg-3 my-auto text-general">
                            <p class="py-3">Responsable</p>
                        </div>
                        <div class="col-md-4 col-lg-3 my-auto text-general">
                            <p class="">Fecha de creación</p>
                        </div>
                        <div class="col-md-2 col-lg-2 my-auto text-center icon-option">
                            <el-tooltip content="Subir archivo" effect="light" placement="right" visible-arrow>
                                <i v-if="idFolderSelected != null" class="icon-plus-box f-20 cr-pointer" @click="agregarArchivo" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="listado-archivos px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2 ">
                        <card-folder-file v-for="data in selectedFolderFiles" :key="data.id"
                        :fileName="data.nombre"
                        :file="data.ruta"
                        :fileType="tipoArchivo(data.ruta)"
                        :idTipo="data.id_tipo"
                        :visualizar="data.visualizador_archivo"
                        :userName="data.user.nombre"
                        :userImg="data.user.foto"
                        :dateCreated="fechaCreacionArchivo(data.created_at)"
                        @dowloadFile="handleDowloadFile(data.ruta)"
                        @editFile="handleEditFile(data)"
                        @deleteFile="deleteFileOpen(data.id)"
                        />
                    </div>
                </div>  
            </div>
        </div>
        <!-- partials  -->
        <modal-agregar-carpeta ref="abrirAgregarCarpeta" />
        <modal-eliminar ref="abrirEliminarCarpeta" title="Eliminar carpeta" mensaje="¿Está seguro que quiere eliminar esta carpeta?" @delete="handleDeleteFolder" />
        <modal-adjuntos-general ref="abrirAgregarArchivoCarpeta" @saveFile="handleUploadFile" view-file/>
        <modal-eliminar ref="abrirEliminarArchivo" title="Eliminar archivo" mensaje="¿Está seguro que quiere eliminar este archivo?" @delete="handleDeleteFolderFile" />
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import modalAgregarCarpeta from './partials/modalAgregarCarpeta'
export default {
    name: 'ServiciosDocumentacion',
    components: {
        modalAgregarCarpeta,
    },
    data() {
        return {
            idFolderSelected: null,
            fileToDelete: '',
            idProyecto: this.$route.params.id_proyecto,
            idServicio: this.$route.params.id_control_piso, 
        }
    },
    computed: {
        ...mapGetters({
            foldersList: 'obras/servicios/documentacion/getFoldersFiles',
            selectedFolderFiles: 'obras/servicios/documentacion/archivosList',
            key: 'files/files/key'
        })
    },
    async mounted() {
        await this.listFolders()
        if (this.foldersList.length > 0) {
             this.idFolderSelected = this.foldersList[0].id
            this.loadFolderFiles(this.idFolderSelected)
        }
        
    },
    methods: {
        ...mapActions({
            Action_get_documentacion_carpetas: 'obras/servicios/documentacion/Action_get_documentacion_carpetas',
            Action_get_archivos_carpetas: 'obras/servicios/documentacion/Action_get_archivos_carpetas',
            Action_delete_carpeta: 'obras/servicios/documentacion/Action_delete_carpeta',
            uploadFile: 'files/files/Action_save_file_to_aws',
            Action_crear_archivos_carpetas: 'obras/servicios/documentacion/Action_crear_archivos_carpetas',
            Action_editar_archivos_carpetas: 'obras/servicios/documentacion/Action_editar_archivos_carpetas',
            Action_delete_archivos_carpetas: 'obras/servicios/documentacion/Action_delete_archivos_carpetas'
        }),
        ...mapMutations({
            set_archivos: 'obras/servicios/documentacion/set_archivos'
        }),
        crearCarpeta(){
            this.$refs.abrirAgregarCarpeta.toggle(this.idProyecto, this.idServicio);
        },
        editFolder(data){
            this.$refs.abrirAgregarCarpeta.toggle(this.idProyecto, this.idServicio, data)
        },
        deleteFolder(id){
            this.idFolderSelected = id
            this.$refs.abrirEliminarCarpeta.toggle()
        },
        async handleDeleteFolder(){
            await this.Action_delete_carpeta({idProyecto: this.idProyecto, idControlPiso: this.idServicio, idDocumento: this.idFolderSelected})
            this.listFolders()
            this.set_archivos([])
        },
        async loadFolderFiles(id){
            this.idFolderSelected = id
            await this.Action_get_archivos_carpetas({ idProyecto: this.idProyecto, idControlPiso: this.idServicio, idCarpeta:id})
        },
        tipoArchivo(archivo){
            let file = archivo.split('.')
            let ext = file[file.length - 1]
            return ext
        },
        fechaCreacionArchivo(fecha){
            return moment(fecha).format('DD MMM YYYY')
        },
        handleDowloadFile(){

        },
        agregarArchivo(){
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        async listFolders(){
            let obj = {
                idProyecto: this.idProyecto,
                idControlPiso: this.idServicio,
            }
            await this.Action_get_documentacion_carpetas(obj);
        },
        async handleUploadFile(data){
            let ruteFile = ''
            
            if(data[0].filesToUpload && data[0].filesToUpload.lastModified != 0) {
                await this.uploadFile({ 
                    file: data[0].filesToUpload,
                    path: '/obras/servicios/documentacion' 
                })
            } else if(data[0].filesToUpload){
                ruteFile = data[0].filesToUpload.route
            }

            let payload = {
                idProyecto: this.idProyecto,
                idControlPiso: this.idServicio,
                idCarpeta: this.idFolderSelected,
                nombre: data[0].fileName,
                id_tipo: data[0].fileType,
                ruta: ruteFile.length ? ruteFile : this.key,
                idArchivo: data[0].idFile,
                visualizador_archivo: data[0].radioVisualizar
            } 
            if (data[1]) {
                await this.Action_editar_archivos_carpetas(payload)
            } else {
                await this.Action_crear_archivos_carpetas(payload)
            }

            this.listFolders()
            this.loadFolderFiles(this.idFolderSelected)
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        async handleEditFile(data){
            data.archivo = data.ruta
            this.$refs.abrirAgregarArchivoCarpeta.toggle(data, true)
        },
        deleteFileOpen(id_file){
            this.fileToDelete = id_file
            this.$refs.abrirEliminarArchivo.toggle()
        },
        async handleDeleteFolderFile(){
            await this.Action_delete_archivos_carpetas({ 
                idProyecto: this.idProyecto,
                idControlPiso: this.idServicio,
                idCarpeta: this.idFolderSelected,
                idArchivo: this.fileToDelete
            })
            this.listFolders()
            this.loadFolderFiles(this.idFolderSelected)
        },
    },
}
</script>

<style lang="scss" scoped>
.section-servicios-documentacion{
    height: calc(100vh - 385px);
    .listado-carpetas{
        //min-height: 400px;
        max-height: 400px;
        .carpeta{
            .cant-archivos{
                height: 16px;
            }
            &:hover{
                background: var(--color-general);
                color: #fff !important;
                cursor: pointer;
                .cant-archivos{
                    background: white;
                    color: var(--color-general) !important;
                }
            }
        }
    }
    .listado-archivos{
        //min-height: 400px;
        max-height: 400px;
        .archivo{
            &:hover{
                background-color: var(--color-f5);
            }
        }
    }
}
</style>